/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { PageProps } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import Seo from "./seo"
import { AllPages } from "../interfaces/allPages"
import { Page as PageModel } from "../interfaces/page"

type DataProps = AllPages & { page: PageModel }

const Page: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const page = data.page;
  const allPages = {
    pages: data.pages,
    projects: data.projects
  }

  return (
    <Layout allPages={allPages} color={page.color || undefined} page={page}>
      <Seo
        title={page.title}
        description={page.excerpt}
        pathname={location.pathname}
        image={page.cover.childImageSharp.resize.src}
      />
      <div
        sx={{
          variant: page.custom ? `content.custom` : `content.page`,
        }}
        data-testid="page-content"
      >
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export default Page
