import { graphql } from "gatsby"
import PageComponent from "../components/page"

export default PageComponent

export const query = graphql`
  query ($slug: String!, $homepagePageLimit: Int!, $homepageProjectLimit: Int!) {
    page(slug: { eq: $slug }) {
      title
      slug
      excerpt
      body
      color
      custom
      cover {
        childImageSharp {
          resize(width: 1200, quality: 85) {
            src
          }
        }
      }
    }
    pages: allPage(sort: { fields: title, order: ASC }, limit: $homepagePageLimit) {
      nodes {
        slug
        title
        hierarchy
        cover {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 90)
          }
        }
      }
    }
    projects: allProject(sort: { fields: date, order: DESC }, limit: $homepageProjectLimit) {
      nodes {
        slug
        title: shortTitle
        cover {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 90)
          }
        }
      }
    }
  }
`
